// @ts-ignore
import React        from "react"
import {FindMd}     from '../lib/FindMd'
import {ThisLayout} from '../components/thisLayout'

const Index = ({data}) => {
  let md_data = FindMd({file_name: 'manga.md'})
  // let slides: { filename: string, texts: string[] | string[][] }[] = [
  //   {
  //     filename: "manga.png", texts: [],
  //   },
  //   {
  //     filename: "確かに持ち家やマンションなどの不動産を所有している場合.png", texts: [],
  //   },
  //   {
  //     // filename: "リースバック？.png", texts: [],
  //     filename: "leaseback_q.png", texts: [],
  //   },
  // ]

  return <>
    <ThisLayout md_data={md_data}>
      {/*<div className="w-full">*/}
      {/*  <HeaderSlider slides={slides}/>*/}
      {/*</div>*/}
    </ThisLayout>
  </>

}

export default Index
